<template>
  <div>
    <v-btn
      :disabled="disabled"
      color="#042956"
      @click="nextStep"
      class="white--text"
    >
      {{ message }}

      <v-icon right>
        mdi-arrow-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BtnNextStep",
  props: {
    message: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    nextStep() {
      this.$emit("nextStep");
    }
  }
};
</script>
